import React, { useState, useEffect, useRef, memo } from "react";
import PropTypes from "prop-types";
import "./Notification.css";

const Notification = memo(({ message, onClose, title, imageUrl, duration = 7.5, color }) => {
  const [isVisible, setIsVisible] = useState(true);
  const progressBarRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleClose();
    }, (duration - 0.1) * 1000);

    return () => clearTimeout(timer);
  }, [duration]);

  useEffect(() => {
    if (progressBarRef.current) {
      progressBarRef.current.style.animationDuration = `${duration}s`;
    }
  }, [duration]);

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  return (
    <div
      className={`notification ${isVisible ? "show" : "hide"}`}
      onClick={handleClose}
      style={color ? { background: `linear-gradient(to right, ${color} 20%, rgba(40, 40, 40, 0.5) 60%)`, backgroundClip: 'content-box' } : null}
    >
      <div className="notification-image">
        <img src={imageUrl} alt="" />
      </div>
      <div className="notification-content">
        {title && <div className="notification-title">{title}</div>}
        {message && <div className="notification-message">{message}</div>}
        <div className="notification-button-container">Tap To Close</div>
        <div
          ref={progressBarRef}
          className="bar"
          style={{
            animationName: 'progressBar',
            animationTimingFunction: 'linear',
            animationFillMode: 'forwards',
          }}
        />
      </div>
    </div>
  );
});

Notification.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  duration: PropTypes.number,
  title: PropTypes.string,
  imageUrl: PropTypes.string,
  color: PropTypes.string,
};

export default Notification;