import React, { createContext, useContext, useReducer, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import Notification from "./Notifications";

const NotificationContext = createContext();

const notificationReducer = (state, action) => {
  switch (action.type) {
    case "ADD":
      return [...state, action.payload];
    case "REMOVE":
      return state.filter((item) => item.id !== action.payload);
    default:
      return state;
  }
};

const NotificationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(notificationReducer, []);

  const addNotification = useCallback((notification) => {
    const id = uuidv4();
    dispatch({
      type: "ADD",
      payload: { ...notification, id },
    });
  }, []);

  const removeNotification = useCallback((id) => {
    setTimeout(() => {
      dispatch({ type: "REMOVE", payload: id });
    }, 80);
  }, []);

  const contextValue = useMemo(() => ({
    addNotification,
    removeNotification,
  }), [addNotification, removeNotification]);

  return (
    <NotificationContext.Provider value={contextValue}>
      <div className="notification-container">
        {state.map((notification) => (
          <Notification
            key={notification.id}
            title={notification.title}
            message={notification.message}
            imageUrl={notification.imageUrl}
            duration={notification.duration}
            color={notification.color}
            onClose={() => removeNotification(notification.id)}
          />
        ))}
      </div>
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error("useNotification must be used within a NotificationProvider");
  }
  return context;
};

export { NotificationProvider, useNotification };