import React from 'react';
import { errorExplinationMark } from '../Icons';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    this.setState({ error, errorInfo });
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <div className="rateLimitPopup updatedScrollBar rateLimitAdditonal">
            <img src={errorExplinationMark} onClick={null} alt="" />
            <div className="ratelimit-content">
              <div className="ratelimit-message">
                Something went wrong
              </div>
              <div className="ratelimit-message2">
                <details style={{ whiteSpace: 'pre-wrap' }}>
                  {this.state.error && this.state.error.toString()}
                  <br />
                  {this.state.errorInfo && this.state.errorInfo.componentStack}
                </details>
              </div>
              <div className={`goBackPopup`} onClick={() => window.location.reload()}>Reload</div>
            </div>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;